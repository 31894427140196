<template>
  <div class="reports_section_summary" :class="isFullScreenMode?'reports_section_full_screen':''">
    <b-table sticky-header
             class="reports-fixed-header"
             :fields="columns" :items="getUsers"
             @row-clicked="rowClicked"
             :filter="query_filter" :filter-included-fields="['username']">
      <!--      <template #thead-top="data">-->
      <!--        <b-tr class="reports-fixed-header">-->
      <!--          <b-th colspan="" class="text-center"><span>User</span></b-th>-->
      <!--          <b-th colspan="" class="text-center"><span>Balance</span></b-th>-->
      <!--          <b-th colspan="" class="text-center"><span>Received</span></b-th>-->
      <!--          <b-th colspan="" class="text-center"><span>Used</span></b-th>-->
      <!--          <b-th colspan="" class="text-center"><span>Orders pending</span></b-th>-->
      <!--          <b-th colspan="" class="text-center"><span>Received average</span></b-th>-->
      <!--          <b-th colspan="" class="text-center"><span>Used average</span></b-th>-->
      <!--          <b-th colspan="" class="text-center"><span>Days left</span></b-th>-->
      <!--        </b-tr>-->
      <!--      </template>-->
      <template #cell(username)="{item}">
        <div class="d-flex">
          <div class="">
            <b-form-checkbox v-model="selected" :value="item.id"></b-form-checkbox>
          </div>
          <div class="">
            <span class="pb-0 mb-0 text-left font-weight-bolder" v-tippy="{ placement : 'top',  arrow: true }"
                  :content="item.username +'<br>'+ item.email + '<br>' + item.alpha3_code ">
                    {{ (item.username) ? item.username.toUpperCase().substring(0, 15) : '' }}</span>
          </div>
        </div>
      </template>
      <template #cell(pending_price)="{item}">
        <span>{{ item.pending_price|euro }} / {{ item.total_rows }}</span>
      </template>
      <template #cell(balance)="{item}">
        <span>{{ item.balance|euro }}</span>
      </template>
      <template #cell(received)="{item}">
        <span>{{ item.received|euro }}</span>
      </template>
      <template #cell(used)="{item}">
        <span>{{ item.used|euro }}</span>
      </template>
      <template #cell(received_average_day)="{item}">
        <span>{{ item.received_average_day|euro }}</span>
      </template>
      <template #cell(used_average_day)="{item}">
        <span>{{ item.used_average_day|euro }}</span>
      </template>
      <template #cell(time)="{item}">
        <span>{{ item.time|roundNumber }}</span>
      </template>
    </b-table>
  </div>
</template>

<script>
import fullscreen from "@/utils/fullscreen";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "predicted_summary",
  mixins: [fullscreen],
  data: () => ({
    columns: [
      {key: "username", label: "Username", sortable: true, stickyColumn: true,},
      {key: "balance", label: "Balance", sortable: true, stickyColumn: true,},
      {key: "received", label: "Received", sortable: true, stickyColumn: true,},
      {key: "used", label: "Used", sortable: true, stickyColumn: true,},
      {key: "pending_price", label: "Orders pending", sortable: true, stickyColumn: true,},
      {key: "received_average_day", label: "Received average", sortable: true, stickyColumn: true,},
      {key: "used_average_day", label: "Used average", sortable: true, stickyColumn: true,},
      {key: "time", label: "Days left", sortable: true, stickyColumn: true,},
    ],
    selected: [],
    query_filter: null,
  }),
  computed: {
    ...mapGetters("reportCreditPredictedStore", ["getUsers"])
  },
  methods: {
    ...mapMutations("reportCreditPredictedStore", ["setUserSelected"]),
    rowClicked() {
    }
  },
  watch: {
    selected() {
      this.setUserSelected(this.selected)
    }
  }
}
</script>

<style scoped lang="scss">
.table.b-table > thead > tr > th {
  background-color: white;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 2;
}
</style>
