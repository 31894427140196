<template>
  <div>
    <b-overlay :show="showOverlay" rounded="lg">
      <div class="w-100 justify-content-center" v-if="$store.state.showReportsAlert">
        <b-alert show dismissible variant="dark" class="font-weight-bold text-white"> The data shown in the Pie Chart:
          The outer circle is the current data.
          The inner circle is the previous data</b-alert>
      </div>
      <!------------------------------------------------ START BTN ACTIONS -------------------------------------->
      <b-row>
        <div class="col-lg-9 col-md-12 col-sm-12 justify-content-around d-flex pr-0 pl-0">
          <span>From previous: {{ $store.state.reportCreditPurchaseStore.params.from_previous }}</span>
          <span>To previous: {{ $store.state.reportCreditPurchaseStore.params.to_previous }}</span>
          <span>From current: {{ $store.state.reportCreditPurchaseStore.params.from_current }}</span>
          <span>To current: {{ $store.state.reportCreditPurchaseStore.params.to_current }}</span>
        </div>
        <div class="col-lg-3 col-md-12 col-sm-12 pl-0 justify-content-end d-flex">
          <b-button @click="enterToFullscreen('section_graphic')"
                    variant="outline-primary" size="sm"
                    v-tippy="{ placement : 'top',  arrow: true }"
                    content="Full screen view graphic">
            <i class="fa fa-expand"> Graphic</i>
          </b-button>
          <b-button @click="enterToFullscreen('section_summary')"
                    variant="outline-primary" size="sm"
                    v-tippy="{ placement : 'top',  arrow: true }"
                    content="Full screen view table">
            <i class="fa fa-expand"> Table</i>
          </b-button>
          <b-button @click="enterToFullscreen('reports')" variant="outline-primary" size="sm"
                    v-tippy="{ placement : 'top',  arrow: true }"
                    content="Full screen view window">
            <i class="fa fa-arrows-alt"> Window</i>
          </b-button>
          <b-button @click="exportCredit" variant="outline-primary" size="sm"
                    v-tippy="{ placement : 'top',  arrow: true }"
                    content="Download excel">
            <i class="fa fa-download" aria-hidden="true"></i>
          </b-button>
        </div>
      </b-row>
      <!------------------------------------------------ END BTN ACTIONS -------------------------------------->
      <!------------------------------------------------ START SECTIONS -------------------------------------->
      <b-row v-if="params.page === 'purchase'">
        <div class="col-lg-6 col-md-12 col-sm-12 custom_div" id="section_graphic">
          <graphic-section/>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 custom_div" id="section_summary">
          <summary-section/>
        </div>
      </b-row>
      <b-row v-if="params.page === 'revenue'">
        <div class="col-lg-6 col-md-12 col-sm-12 custom_div" id="section_graphic">
          <revenue-graphic-section/>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 custom_div" id="section_summary">
          <revenue-summary-section/>
        </div>
      </b-row>
      <b-row v-if="params.page === 'prediction'">
        <div class="col-lg-6 col-md-12 col-sm-12 custom_div" id="section_graphic">
          <predicted-graphic/>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 custom_div" id="section_summary">
        <predicted-summary/>
        </div>
      </b-row>
      <!------------------------------------------------ END SECTIONS -------------------------------------->
      <!------------------------------------------------ START FILTERS -------------------------------------->
      <b-row class="mt-2 justify-content-center">
        <div class="col-lg-2 col-md-4 col-sm-12" v-if="['purchase','revenue'].includes(params.page)">
          <select class="form-control" v-model="params.time" @change="loadDataPageSelected">
            <option :value="item.key" v-for="(item, index) in times" :key="index">{{ item.value }}</option>
          </select>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-12">
          <select class="form-control" v-model="params.graphic" @change="setTypeGraphic">
            <option value="horizontalBar">Horizontal Bar</option>
            <option value="pie">Pie</option>
          </select>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-12">
          <b-button variant="primary" class="btn-block" @click="showModalDates">Select dates</b-button>
        </div>
        <div v-if="['purchase'].includes(params.page)" class="col-lg-2 col-md-4 col-sm-12">
          <select class="form-control" v-model="params.showDistributorBalance"
                  @change="groupBalances($event.target.value)">
            <option :value="true">Show distributor balance</option>
            <option :value="false">Show dealers balance</option>
          </select>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-12">
          <select class="form-control" v-model="params.page" @change="switchPage">
            <option value="purchase">Credits purchase</option>
            <option value="prediction">Credits prediction</option>
            <option value="revenue">Credits revenue</option>
          </select>
        </div>
      </b-row>
      <!------------------------------------------------ END FILTERS -------------------------------------->
    </b-overlay>
    <modal-dates :page-selected="params.page" @load_data="loadModalDates"/>
  </div>
</template>
<script>
import graphicSection from "./purchase_graphic.vue";
import revenueGraphicSection from "./revenue_graphic.vue";
import summarySection from "./purchase_summary.vue";
import revenueSummarySection from "./revenue_summary.vue";
import predictedSummary from "./predicted_summary"
import predictedGraphic from "./predicted_graphic"
import modalDates from "./_modalDates";
import {get} from "@/services/api";
import {mapMutations, mapState} from "vuex";
import fullscreen from "@/utils/fullscreen";
import show_alert_mixin from "@/utils/show_alert_mixin";

const times = [
  {key: 'month', value: 'Last month'},
  {key: 'trimester', value: 'Last trimester'},
  {key: 'semester', value: 'Last semester'},
  {key: 'year', value: 'Last year'},
]

export default {
  name: "AnalyticCredits",
  components: {
    graphicSection,
    summarySection,
    modalDates,
    revenueSummarySection,
    revenueGraphicSection,
    predictedSummary,
    predictedGraphic
  },
  mixins: [fullscreen, show_alert_mixin],
  data: () => ({
    params: {
      time: 'month',
      from_previous: null,
      to_previous: null,
      from_current: null,
      to_current: null,
      compare: 'week',
      market: false,
      page: 'purchase',
      graphic: 'horizontalBar',
      showDistributorBalance: true
    },
    showOverlay: false,
    times: times,
  }),
  mounted() {
    this.loadInitData()
  },
  computed: {
    ...mapState("reportCreditPurchaseStore", ['params'])
  },
  methods: {
    ...mapMutations(['loaderManager']),
    ...mapMutations("reportCreditPurchaseStore",["setPurchaseUserSelected"]),
    loadInitData() {
      this.showOverlay = true
      this.$store.commit('reportCreditPurchaseStore/setParams', this.params)
      this.setPurchaseUserSelected([])
      this.$store.dispatch('reportCreditPurchaseStore/fetchUsers').then(() => this.showOverlay = false)
    },
    switchPage() {
      this.$store.state.reportCreditPurchaseStore.users_selected = []
      this.$store.state.reportCreditPurchaseStore.revenue_users_selected = []
      this.resetParams()
      this.loadDataPageSelected()
    },
    loadModalDates(params) {
      this.params.from_previous = params.from_previous
      this.params.to_previous = params.to_previous
      this.params.from_current = params.from_current
      this.params.to_current = params.to_current
      this.loadDataPageSelected()
    },
    resetTimeDates() {
      this.params.from_previous = null
      this.params.to_previous = null
      this.params.from_current = null
      this.params.to_current = null
      this.loadDataPageSelected()
    },
    resetParams() {
      this.params = {
        time: 'month',
        from_previous: null,
        to_previous: null,
        from_current: null,
        to_current: null,
        compare: 'week',
        market: false,
        page: this.params.page,
        graphic: 'horizontalBar',
        showDistributorBalance: true
      }
      this.$store.commit("reportCreditPurchaseStore/setShowDistributorBalance", true)
    },
    loadDataPageSelected() {
      this.showOverlay = true
      if (this.params.page === 'revenue') {
        this.$store.commit('reportCreditRevenueStore/setParams', this.params)
        this.$store.dispatch("reportCreditRevenueStore/fetchRevenueSummary", this.params).then(() => this.showOverlay = false)
      } else if (this.params.page === 'purchase') {
        this.$store.commit('reportCreditPurchaseStore/setParams', this.params)
        this.$store.dispatch('reportCreditPurchaseStore/fetchUsers').then(() => this.showOverlay = false)
      }else{
        this.$store.commit('reportCreditPredictedStore/setParams', this.params)
        this.$store.dispatch('reportCreditPredictedStore/fetchUsers').then(() => this.showOverlay = false)
      }
    },
    setTypeGraphic() {
      if(this.params.graphic === "pie"){
        this.$store.state.showReportsAlert = true
        setTimeout(()=>{
          this.$store.state.showReportsAlert = false
        },10000)
      }
      if (this.params.page === 'purchase') {
        this.$store.commit('reportCreditPurchaseStore/setGraphicType', this.params.graphic)
      } else if (this.params.page === 'revenue') {
        this.$store.commit('reportCreditRevenueStore/setGraphicType', this.params.graphic)
      }else {
        this.$store.commit('reportCreditPredictedStore/setGraphicType', this.params.graphic)
      }
    },
    showModalDates() {
      if (this.params.compare)
        this.$root.$emit('bv::show::modal', 'modal-report-credit-dates', '#btnShow')
      else
        this.$root.$emit('bv::hide::modal', 'modal-report-credit-dates', '#btnShow')
    },
    exportCredit() {
      this.loaderManager(true)
      let url = 'credit-export-excel'
      if (this.params.page === 'revenue') {
        url = 'credit-revenue-export-excel'
      }
      if (this.params.page === 'prediction') {
        url = 'credit-prediction-export-excel'
      }
      get(`reports/${url}?time=${this.params.time}&from_previous=${this.params.from_previous}&to_previous=${this.params.to_previous}&from_current=${this.params.from_current}&to_current=${this.params.to_current}`, null, true, 'arraybuffer').then(({data}) => {
        const fileURL = window.URL.createObjectURL(new Blob([data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `sales report credits ${this.params.page}.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.loaderManager(false);
      }).catch(() => {
        this.loaderManager(false);
      });
    },
    groupBalances(value) {
      if (this.params.page === 'purchase') {
        this.$store.commit("reportCreditPurchaseStore/setShowDistributorBalance", value === 'true')
        this.$store.dispatch("reportCreditPurchaseStore/groupDealersBalanceDistributor")
      }
    }
  }
}
</script>
<style scoped lang="scss">
.chart_full_screen {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

@media only screen and (max-width: 600px) {
  .custom_div {
    margin: 1rem 0;
  }
}
</style>
