var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reports_section_summary",class:_vm.isFullScreenMode?'reports_section_full_screen':''},[_c('b-table',{attrs:{"bordered":"","small":"","fields":_vm.columns,"items":_vm.users},scopedSlots:_vm._u([{key:"cell(username)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',{},[_c('b-form-checkbox',{attrs:{"value":item.user_id},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('div',{},[_c('span',{staticClass:"pb-0 mb-0 reports_dot",style:({background: item.colors.backgroundColor})}),_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement : 'top',  arrow: true }),expression:"{ placement : 'top',  arrow: true }"}],staticClass:"pb-0 mb-0 text-left",attrs:{"content":item.username +'<br>'+ item.email + '<br>' + item.alpha3_code}},[_vm._v(" "+_vm._s((item.username) ? item.username.toUpperCase().substring(0, 15) : ''))])])])]}},{key:"cell(credits)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bolder",class:item.credits < 0 ? 'text-danger': ''},[_vm._v(_vm._s(_vm._f("euro")(item.credits)))])]}},{key:"cell(price)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm._f("euro")(item.price)))])]}},{key:"cell(average)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm._f("number")(item.average))+"%")])]}}])}),_c('div',{staticStyle:{"width":"100%"}},[_c('table',{staticClass:"table bordered"},[_vm._m(0),_c('tbody',[_c('tr',[_c('td',{staticClass:"text-right font-weight-bolder"},[_vm._v(" "+_vm._s(_vm._f("number")(_vm.$store.state.reportCreditRevenueStore.revenue_total.movements))+" ")]),_c('td',{staticClass:"text-right font-weight-bolder"},[_vm._v(_vm._s(_vm._f("euro")(_vm.$store.state.reportCreditRevenueStore.revenue_total.credits)))]),_c('td',{staticClass:"text-right font-weight-bolder"},[_vm._v(_vm._s(_vm._f("euro")(_vm.$store.state.reportCreditRevenueStore.revenue_total.price)))]),_c('td',{staticClass:"text-right font-weight-bolder"},[_vm._v(_vm._s(_vm._f("number")(_vm.$store.state.reportCreditRevenueStore.revenue_total.average)))])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"text-center"},[_c('th',{staticClass:"text-right"},[_vm._v("Movements")]),_c('th',{staticClass:"text-right"},[_vm._v("Credits")]),_c('th',{staticClass:"text-right"},[_vm._v("Price")]),_c('th',{staticClass:"text-right"},[_vm._v("Average")])])])}]

export { render, staticRenderFns }