<template>
  <div class="reports_section_summary" :class="isFullScreenMode?'reports_section_full_screen':''">
    <div class="w-100">
      <b-input v-model="query_filter" placeholder="Type to search dealers"></b-input>
    </div>
    <b-table sticky-header="35vw" bordered small selectable select-mode="single" :fields="columns" :items="users" @row-clicked="rowClicked" :filter="query_filter" :filter-included-fields="['username','children']">
      <template #thead-top="data">
        <b-tr class="reports-fixed-header">
          <b-th colspan="" class="text-center"><span>User</span></b-th>
          <b-th colspan="2" class="text-center reports_pointer"
                v-tippy="{ placement : 'top',  arrow: true }"
                content="Graphic credits received (Default option)"
                :class="$store.state.reportCreditPurchaseStore.purchase_column_selected === 'received'?'reports_columns_active':''"
                @click="$store.commit('reportCreditPurchaseStore/setPurchaseColumnSelected','received')"
          ><span>Received</span></b-th>
          <b-th colspan="2" class="text-center reports_pointer"
                v-tippy="{ placement : 'top',  arrow: true }"
                content="Graphic credits used"
                :class="$store.state.reportCreditPurchaseStore.purchase_column_selected === 'used'?'reports_columns_active':''"
                @click="$store.commit('reportCreditPurchaseStore/setPurchaseColumnSelected','used')"
          ><span>Used</span></b-th>
          <b-th colspan="2" class="text-center reports_pointer"
                v-tippy="{ placement : 'top',  arrow: true }"
                content="Graphic credits remaining"
                :class="$store.state.reportCreditPurchaseStore.purchase_column_selected === 'remaining'?'reports_columns_active':''"
                @click="$store.commit('reportCreditPurchaseStore/setPurchaseColumnSelected','remaining')"><span>Remaining</span>
          </b-th>
        </b-tr>
      </template>
      <template #cell(username)="{item}">
        <div class="d-flex">
          <div class="">
            <b-form-checkbox v-model="selected" :value="item.id"></b-form-checkbox>
          </div>
          <div class="">
            <span :style="{background: item.colors.backgroundColor}" class="pb-0 mb-0 reports_dot"></span>
            <span class="pb-0 mb-0 text-left" :class="item.children.length?'font-weight-bold':'font-weight-bolder'"
                  v-tippy="{ placement : 'top',  arrow: true }"
                  :content="item.username +'<br>'+ item.email + '<br>' + item.alpha3_code ">
              {{ (item.username) ? item.username.toUpperCase().substring(0, 15) : '' }}</span>
          </div>
        </div>
      </template>
      <template #cell(current.received)="{item}" style="cursor: pointer">
            <span class="font-weight-bolder"
                  :class="item.current.received < 0 ? 'text-danger': ''">{{ item.current.received|euro }}</span>
      </template>
      <template #cell(current.used)="{item}">
            <span class="font-weight-bolder"
                  :class="item.current.used < 0 ? 'text-danger': ''">{{ item.current.used|euro }}</span>
      </template>
      <template #cell(current.remaining)="{item}">
            <span class="font-weight-bolder"
                  :class="item.current.remaining < 0 ? 'text-danger': ''">{{ item.current.remaining|euro }}</span>
      </template>
      <template #cell(previous.received)="{item}" class="pull-right">
        <rows-balances :variation="item.variation.received" :amount="item.previous.received" :filter="'euro'"/>
      </template>
      <template #cell(previous.used)="{item}">
        <rows-balances :variation="item.variation.used" :amount="item.previous.used" :filter="'euro'"/>
      </template>
      <template #cell(previous.remaining)="{item}">
        <rows-balances :variation="item.variation.remaining" :amount="item.previous.remaining" :filter="'euro'"/>
      </template>
      <template #row-details="{item}">
        <row-table-users style="border: 1px solid black; padding-bottom: 0; margin-bottom: 0" :array_sub_users="item.children">
          <template #default="item">
            <b-form-checkbox v-model="selected" :value="item.id">
              <span
                class="pb-0 mb-0 font-weight-bolder">
                {{ (item.username) ? item.username.toUpperCase().substring(0, 20) : '' }}
              </span>
            </b-form-checkbox>
          </template>
        </row-table-users>
      </template>
    </b-table>
  </div>
</template>

<script>
import rowTableUsers from "./_detailPurchaseUser"
import rowsBalances from "../_rowsVariation"
import {mapMutations, mapState} from "vuex";
import fullscreen from "@/utils/fullscreen";

export default {
  name: "purchase_summary",
  components: {
    rowTableUsers,
    rowsBalances
  },
  props: {
    users: {
      default: []
    },
  },
  mixins: [fullscreen],
  data: () => ({
    columns: [
      {key: 'username', label: '',  sortable: true},
      {key: 'current.received', label: 'current', tdClass: 'text-right reports_pointer', thClass: 'text-center',  sortable: true},
      {key: 'previous.received', label: 'previous', tdClass: 'text-right reports_pointer', thClass: 'text-center',  sortable: true},
      {key: 'current.used', label: 'current', tdClass: 'text-right reports_pointer', thClass: 'text-center',  sortable: true},
      {key: 'previous.used', label: 'previous', tdClass: 'text-right reports_pointer', thClass: 'text-center',  sortable: true},
      {key: 'current.remaining', label: 'current', tdClass: 'text-right reports_pointer', thClass: 'text-center',  sortable: true},
      {key: 'previous.remaining', label: 'previous', tdClass: 'text-right reports_pointer', thClass: 'text-center',  sortable: true},
    ],
    selected: [],
    query_filter: null
  }),
  computed: {
    ...mapState('reportCreditPurchaseStore', ['users'])
  },
  methods: {
    ...mapMutations("reportCreditPurchaseStore",["setPurchaseUserSelected"]),
    rowClicked(e) {
      e._showDetails = !e._showDetails && e.children.length
    },
  },
  watch: {
    selected() {
      this.setPurchaseUserSelected(this.selected)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
