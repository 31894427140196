<template>
  <div class="reports_section_graphic" :class="isFullScreenMode?'reports_section_full_screen':''">
    <canvas ref="predicted_graphic" id="canvas" width="400" height="400"></canvas>
  </div>
</template>

<script>
import fullscreen from "@/utils/fullscreen";
import {mapGetters, mapState} from "vuex";
import * as Chart from "chart.js";

export default {
  name: "predicted_graphic",
  mixins: [fullscreen],
  computed: {
    ...mapState('reportCreditPredictedStore', ['users_selected']),
    ...mapGetters('reportCreditPredictedStore', ['predicted_graphic', 'predicted_graphic_type'])
  },
  mounted() {
    this.predicted_graphic_type = 'horizontalBar'
    this.print_graphic()
  },
  destroyed() {
    this.$store.state.reportCreditPredictedStore.predicted_graphic.type = 'horizontalBar'
  },
  data: () => ({
    chart: null
  }),
  methods: {
    async print_graphic() {
      if (!!this.chart) {
        this.chart.destroy()
      }
      this.chart = new Chart(this.$refs.predicted_graphic, {
        type: this.predicted_graphic_type,
        data: {
          labels: this.predicted_graphic.labels,
          datasets: this.predicted_graphic.data,
        },
        options: {
          spanGaps: true,
          responsive: true,
          animations: true,
          scales: {
            y: {
              beginAtZero: true
            },
            yAxes: [{
              stacked: this.predicted_graphic_type === 'horizontalBar'
            }],
            xAxes: [
              {
                stacked: this.predicted_graphic_type === 'horizontalBar',
                ticks: {
                  display: this.predicted_graphic_type === 'horizontalBar',
                }
              }
            ]
          },
          subtitle: {display: false},
          title: {display: false},
          legend: {display: false},
          plugins: {
            zoom: {
              pan: {
                enabled: true,
                mode: 'y'
              },
              zoom: {
                wheel: {
                  enabled: true,
                  speed: 0.5
                },
                enabled: true,
                mode: 'y',
              }
            },
          },
        }
      });
    },
  },
  watch: {
    users_selected: function () {
      this.print_graphic()
    },
    predicted_graphic: function () {
      this.print_graphic()
    }
  }
}
</script>

<style scoped>

</style>
