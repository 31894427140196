<template>
  <div class="reports_section_summary" :class="isFullScreenMode?'reports_section_full_screen':''">
    <b-table bordered small :fields="columns" :items="users">
      <template #cell(username)="{item}">
        <div class="d-flex">
          <div class="">
            <b-form-checkbox v-model="selected" :value="item.user_id"></b-form-checkbox>
          </div>
          <div class="">
            <span :style="{background: item.colors.backgroundColor}" class="pb-0 mb-0 reports_dot"></span>
            <span class="pb-0 mb-0 text-left"
                  v-tippy="{ placement : 'top',  arrow: true }"
                  :content="item.username +'<br>'+ item.email + '<br>' + item.alpha3_code">
              {{ (item.username) ? item.username.toUpperCase().substring(0, 15) : '' }}</span>
          </div>
        </div>
      </template>
      <template #cell(credits)="{item}" style="cursor: pointer">
        <span class="font-weight-bolder" :class="item.credits < 0 ? 'text-danger': ''">{{ item.credits|euro }}</span>
      </template>
      <template #cell(price)="{item}" style="cursor: pointer">
        <span class="font-weight-bolder">{{ item.price|euro }}</span>
      </template>
      <template #cell(average)="{item}" style="cursor: pointer">
        <span class="font-weight-bolder">{{ item.average|number }}%</span>
      </template>
    </b-table>
    <div style="width: 100%">
      <table class="table bordered">
        <thead>
        <tr class="text-center">
          <th class="text-right">Movements</th>
          <th class="text-right">Credits</th>
          <th class="text-right">Price</th>
          <th class="text-right">Average</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="text-right font-weight-bolder">
            {{ $store.state.reportCreditRevenueStore.revenue_total.movements|number }}
          </td>
          <td class="text-right font-weight-bolder">{{ $store.state.reportCreditRevenueStore.revenue_total.credits|euro }}</td>
          <td class="text-right font-weight-bolder">{{ $store.state.reportCreditRevenueStore.revenue_total.price|euro }}</td>
          <td class="text-right font-weight-bolder">{{$store.state.reportCreditRevenueStore.revenue_total.average|number }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import fullscreen from "@/utils/fullscreen";

export default {
  name: "revenue_summary",
  mixins: [fullscreen],
  computed: {
    ...mapState('reportCreditRevenueStore', ['users'])
  },
  data: () => ({
    columns: [
      {key: 'username', label: 'Users', tdClass: '', thClass: 'text-center',  sortable: true},
      {key: 'total_rows', label: '#', tdClass: 'text-right', thClass: 'text-center',  sortable: true},
      {key: 'credits', label: 'Credits', tdClass: 'text-right', thClass: 'text-center',  sortable: true},
      {key: 'price', label: 'Price', tdClass: 'text-right', thClass: 'text-center',  sortable: true},
      {key: 'average', label: 'Average', tdClass: 'text-right', thClass: 'text-center',  sortable: true},
    ],
    selected: [],
    query_filter: null
  }),
  methods: {
    ...mapMutations('reportCreditRevenueStore', ['setUserSelected'])
  },
  watch: {
    selected(){
      this.setUserSelected(this.selected)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
