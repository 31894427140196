<template>
  <table class="table small">
    <tbody>
    <tr v-for="(item, index) in array_sub_users" :key="index">
      <td>
        <slot v-bind="item"></slot>
      </td>
      <td>
        <span class="font-weight-bolder"
              :class="item.current.received < 0 ? 'text-danger': ''">{{ item.current.received|euro }}</span>
      </td>
      <td>
        <rows-balances :variation="item.variation.received" :amount="item.previous.received" :filter="'euro'"/>
      </td>
      <td>
        <span class="font-weight-bolder" :class="item.current.used < 0 ? 'text-danger': ''">{{
            item.current.used|euro
          }}</span>
      </td>
      <td>
        <rows-balances :variation="item.variation.used" :amount="item.previous.used" :filter="'euro'"/>
      </td>
      <td>
        <span class="font-weight-bolder"
              :class="item.current.remaining < 0 ? 'text-danger': ''">{{ item.current.remaining|euro }}</span>
      </td>
      <td>
        <rows-balances :variation="item.variation.remaining" :amount="item.previous.remaining" :filter="'euro'"/>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import rowsBalances from "../_rowsVariation"
import {mapMutations} from "vuex";

export default {
  name: "_tableUsers",
  components: {
    rowsBalances
  },
  props: {
    array_sub_users: [],
  },
  data: () => ({
    selected: [],
  }),
  methods: {
    ...mapMutations("reportCreditPurchaseStore", ["setPurchaseUserSelected"]),
  },
}
</script>

<style scoped lang="scss">
.sub_title_table {
  font-size: 0.6rem;
}

.table {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.custom-control {
  //line-height: 0.7rem;
}
</style>
