var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reports_section_summary",class:_vm.isFullScreenMode?'reports_section_full_screen':''},[_c('b-table',{staticClass:"reports-fixed-header",attrs:{"sticky-header":"","fields":_vm.columns,"items":_vm.getUsers,"filter":_vm.query_filter,"filter-included-fields":['username']},on:{"row-clicked":_vm.rowClicked},scopedSlots:_vm._u([{key:"cell(username)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',{},[_c('b-form-checkbox',{attrs:{"value":item.id},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('div',{},[_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement : 'top',  arrow: true }),expression:"{ placement : 'top',  arrow: true }"}],staticClass:"pb-0 mb-0 text-left font-weight-bolder",attrs:{"content":item.username +'<br>'+ item.email + '<br>' + item.alpha3_code}},[_vm._v(" "+_vm._s((item.username) ? item.username.toUpperCase().substring(0, 15) : ''))])])])]}},{key:"cell(pending_price)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("euro")(item.pending_price))+" / "+_vm._s(item.total_rows))])]}},{key:"cell(balance)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("euro")(item.balance)))])]}},{key:"cell(received)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("euro")(item.received)))])]}},{key:"cell(used)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("euro")(item.used)))])]}},{key:"cell(received_average_day)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("euro")(item.received_average_day)))])]}},{key:"cell(used_average_day)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("euro")(item.used_average_day)))])]}},{key:"cell(time)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("roundNumber")(item.time)))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }